/*!
 * Bootstrap for IE11 v5.0.0-beta2 (https://github.com/coliff/bootstrap-ie11)
 * Copyright 2021 C.Oliff
 * Licensed under MIT (https://github.com/coliff/bootstrap-ie11/blob/main/LICENSE)
 */

@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  @import "reboot";
  @import "utilities";
  @import "breadcrumb";
  @import "buttons";
  @import "card";
  @import "carousel";
  @import "close";
  @import "forms/form-check";
  @import "forms/form-control";
  @import "forms/form-floating-labels";
  @import "forms/form-range";
  @import "forms/form-select";
  @import "modal";
  @import "tooltip";
  @import "helpers/ratio";
}
