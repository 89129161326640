.form-floating {
  display: flex;
  flex-direction: column-reverse;
}

.form-floating > .form-control::-ms-input-placeholder { // stylelint-disable-line
  color: #6c757d;
}

.form-floating input:-ms-input-placeholder,
.form-floating select:-ms-input-placeholder,
.form-floating textarea:-ms-input-placeholder {
  color: transparent;
}

.form-floating input:not(:-ms-input-placeholder) {
  padding-top: 1.25rem;
  padding-bottom: .25rem;
}

.form-floating input:not(:-ms-input-placeholder) ~ label,
.form-floating select:not(:-ms-input-placeholder) ~ label,
.form-floating textarea:not(:-ms-input-placeholder) ~ label {
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 12px;
  color: #777;
}
