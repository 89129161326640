// `pointer-events: none` isn't supported by IE11, setting `cursor: default` just makes it appear less like a link
.pe-none {
  cursor: default;
}

.text-break {
  word-wrap: break-word !important;
}

.user-select-none {
  -ms-user-select: none !important;
}

.user-select-auto {
  -ms-user-select: auto !important;
}

// IE11 doesn't support :not(:focus-within) so the rules defined in Bootstrap 5 are repeated here
.visually-hidden,
.visually-hidden-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
